import axios from "axios";

// import https from "https";
// const BASE_URL = "http://localhost:9090/";
const BASE_URL = process.env.VUE_APP_BASE_URL;
// const MODE = process.env.VUE_APP_MODE;

class AeoAPI {
  async get({ resource, query }) {
    return axios.get(`${resource}`, {
      baseURL: BASE_URL,
      params: query,
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  }
}

export default new AeoAPI();
