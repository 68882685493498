<template>
  <v-footer>
    <v-container class="py-5">
      <v-row>
        <v-col cols="12" md="4">
          <div class="text-left">
            <img
              src="/images/aeo-h-logo-small.png"
              alt=""
              height="40"
              class="mb-4"
            />
          </div>

          <div class="mb-2">
            <router-link
              to="#"
              class="mr-2 d-flex align-center text-decoration-none"
            >
              <span
                class="font-weight-black text-uppercase text-h5 text-truncate"
              >
                AEO Programme
              </span>
            </router-link>
          </div>
          <div class="text-body-1 secondary--text text--lighten-2">
            <div>
              Sri Lanka Customs, No.40, Main Street, Colombo 11, Sri Lanka.
              <br />
              0112-143434, Ext:7855.
            </div>
            <!-- <div class="mt-2">
              <v-btn icon color="secondary lighten-2" class="mr-1">
                <v-icon>mdi-facebook</v-icon>
              </v-btn>
              <v-btn icon color="secondary lighten-2" class="mr-1">
                <v-icon>mdi-twitter</v-icon>
              </v-btn>
              <v-btn icon color="secondary lighten-2" class="mr-1">
                <v-icon>mdi-linkedin</v-icon>
              </v-btn>
              <v-btn icon color="secondary lighten-2" class="mr-1">
                <v-icon>mdi-google-plus</v-icon>
              </v-btn>
            </div> -->
          </div>
        </v-col>
        <v-col v-for="(item, i) in footer" :key="i" cols="12" md="2">
          <div
            class="
              mb-3
              body-2
              font-weight-bold
              secondary--text
              text--lighten-3 text-uppercase
            "
          >
            {{ item.title }}
          </div>

          <div
            v-for="(link, j) in item.links"
            :key="j"
            class="my-1 body-1 font-weight-bold"
          >
            <router-link
              v-if="link.to"
              class="text-decoration-none secondary--text"
              :to="link.to"
            >
              {{ link.label }}
            </router-link>
            <a
              v-else
              class="text-decoration-none secondary--text"
              :href="link.href"
              :target="link.target || 'blank'"
              >{{ link.label }}</a
            >
          </div>
        </v-col>
      </v-row>
      <v-divider class="my-4" />
      <div
        class="
          justify-center
          mt-4
          d-flex
          flex-column flex-sm-row
          align-center
          overline
        "
      >
        <div class="secondary--text text-center">
          ©Risk Management Deractorate | Sri Lanka Customs 2022
        </div>
        <router-link
          to="/privacy"
          class="
            text-decoration-none
            ml-sm-2
            text-center
            secondary--text
            text--lighten-3
          "
        >
          Privacy Policy
        </router-link>
        <router-link
          to="/terms"
          class="
            text-decoration-none
            ml-sm-2
            text-center
            secondary--text
            text--lighten-3
          "
        >
          Terms of Service
        </router-link>
      </div>
    </v-container>
  </v-footer>
</template>

<script>
import aeoApi from "@/api/AeoAPI";
const BASE_URL = process.env.VUE_APP_BASE_URL_IMAGE;

export default {
  async mounted() {
    const documents = await aeoApi.get({
      resource: "documents",
    });

    this.documents = documents.data;

    this.footer[0].links = this.documents.map(function (item) {
      return {
        label: item.name,
        href: `${BASE_URL}${item.file.url}`,
      };
    });
  },
  data() {
    return {
      documents: [],
      footer: [
        {
          title: "Resources",
          links: [
            // {
            //   label: "Operational Guide",
            //   href: ``,
            // },
            // {
            //   label: "User Guide",
            //   to: "#",
            // },
            // {
            //   label: "Presentation",
            //   to: "#",
            // },
            // {
            //   label: "",
            //   to: "#",
            // },
          ],
        },
        {
          title: "International",
          links: [
            {
              label: "WTO",
              href: "https://www.wto.org/",
            },
            // {
            //   label: "Carrers",
            //   to: "#",
            // },
            // {
            //   label: "Press",
            //   to: "#",
            // },
          ],
        },
        {
          title: "Support",
          links: [
            {
              label: "Privacy Policy",
              to: "privacy",
            },
            {
              label: "Terms and Conditions",
              to: "terms",
            },
            // {
            //   label: "Status",
            //   to: "#",
            // },
          ],
        },
      ],
    };
  },
};
</script>
