export const menu1 = [{
  title: 'Home',
  link: "/"
}, {
  title: 'News',
  link: "/articles"
}]

export const NavBar = [{
}]

export default {
  menu1,
  NavBar
}
