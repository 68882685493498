<template>
  <div>
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list dense nav>
        <v-subheader class="text-uppercase font-weight-bold">
          Menu
          <v-btn
            class="inline-block ml-auto"
            icon
            @click="$vuetify.theme.dark = !$vuetify.theme.dark"
          >
            <v-icon :color="$vuetify.theme.dark ? 'secondary' : 'primary'">
              {{
                $vuetify.theme.dark ? "mdi-weather-sunny" : "mdi-weather-night"
              }}
            </v-icon>
          </v-btn>
        </v-subheader>

        <v-list-item v-for="(item, index) in menu" :key="index" link>
          <v-list-item-content>
            <v-list-item-title @click="goTo(item.link)">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template #append>
        <!-- <div class="pa-2">
          <v-btn block class="mb-2" to="/app/pages/login"> Sign In </v-btn>
          <v-btn block class="primary" to="/app/pages/register">
            Sign Up
          </v-btn>
        </div> -->
      </template>
    </v-navigation-drawer>

    <v-btn class="d-md-none drawer-button" rounded @click="drawer = !drawer">
      <v-icon right> mdi-menu </v-icon>
    </v-btn>

    <v-app-bar app height="80">
      <v-container class="px-0 py-0 px-sm-2 fill-height" :fluid="isFluid">
        <router-link
          to="/"
          class="mr-2 d-flex align-center text-decoration-none"
        >
          <img
            src="/images/aeo-h-logo-small.png"
            alt=""
            height="40"
            class="mr-1"
          />
          <!-- <span class="font-weight-black text-uppercase"> AEO </span> -->
        </router-link>

        <div class="d-none d-md-block">
          <v-btn
            v-for="(item, index) in menu"
            :key="index"
            :to="item.link"
            text
            class="mx-1"
          >
            {{ item.title }}
          </v-btn>
        </div>

        <v-spacer />
        <v-btn
          class="d-none d-md-block"
          icon
          @click="$vuetify.theme.dark = !$vuetify.theme.dark"
        >
          <v-icon
            :color="$vuetify.theme.dark ? 'primary' : 'primary lighten-4'"
          >
            {{
              $vuetify.theme.dark ? "mdi-weather-night" : "mdi-weather-sunny"
            }}
          </v-icon>
        </v-btn>
        <!-- <v-btn large text class="inline-block mx-1" to="/app/pages/login">
          Sign In
        </v-btn>
        <v-btn color="primary" to="/app/pages/register" large> Sign Up </v-btn> -->
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
import { menu1 as menu } from "./menus.js";

export default {
  name: "Toolbar",
  props: {
    isFluid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: null,
      menu,
    };
  },

  methods: {
    goTo(link) {
      this.$router.push(link).catch((error) => {
        if (
          error.name !== "NavigationDuplicated" &&
          !error.message.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          console.log(error);
        }
      });
    },
  },
};
</script>

<style scoped>
.drawer-button {
  position: fixed;
  top: 60px;
  left: -22px;
  z-index: 6;
}
</style>
